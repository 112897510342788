@import 'node_modules/@ingka/variables/style';

.form {
  max-width: 500px;
}

.saveButton {
  width: 250px;
}

.cancelButton {
  margin-left: $space-100;
}
