@import 'node_modules/@ingka/variables/style';

.autocomplete_suggestions {
  border: 1px solid $colour-neutral-5;
  color: $colour-text-and-icon-3;
  list-style: none;
  margin-top: 0;
  max-height: 152px;
  overflow-y: auto;
  padding-left: 0;

  &__active {
    background-color: $colour-interactive-emphasised-bg-default;
    color: $colour-neutral-1;
  }

  li {
    padding: 0.5rem;

    &:hover {
      background-color: $colour-interactive-emphasised-bg-default;
      color: $colour-neutral-1;
    }
  }
}
