@import 'node_modules/@ingka/variables/style';
@import 'style/mixins';

.container {
  @include container;

  &:empty {
    display: none;
  }
}

.message {
  margin-bottom: $space-200;
  width: 100%;
}
