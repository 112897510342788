@import 'node_modules/@ingka/variables/style';

.root {
  &__header {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: $space-200;

    &_title {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &_searchwrapper {
      display: flex;
      flex-direction: row;
      margin-top: $space-250;
    }

    &_searchbar {
      margin-right: 1em;
      width: 330px;
    }

    &_selectedfilters {
      margin-top: $space-100;
    }

    &_count {
      margin-top: $space-200;
    }
  }
}
