@import 'node_modules/@ingka/variables/style';

.container {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 $space-150;
}

.label {
  margin: 0 0 0 $space-50;
}
