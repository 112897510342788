@import 'node_modules/@ingka/variables/style';

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > * {
    width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: $space-300;

  > * {
    width: 100%;
  }
}

.header {
  height: 60px;
}

.footer {
  height: 5.5rem;
}

.printContainer {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
