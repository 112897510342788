@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.help {
  color: $colour-text-and-icon-3 !important; /* Sorry about this */
  margin-top: $space-125;
}

.result {
  align-items: center;
  border: $thickness-thin solid $colour-neutral-3;
  border-radius: $radius-s;
  display: flex;
  flex: 0 1;
  margin-top: $space-300;
  padding: $space-150;
  width: auto;
}

.image {
  display: block;
  height: 104px;
  padding-bottom: 0 !important;
  width: 104px;
}

.info {
  margin-left: $space-125;
  min-width: 20rem;
}

.itemNumber {
  color: $colour-text-and-icon-3 !important; /* Sorry about this */
}

.descpription {
  margin-top: $space-50;
}

.button {
  margin-left: $space-550;
}

.error {
  margin-top: $space-300;
}

.loader {
  margin-top: $space-300;
  width: 100%;
}

.custom {
  @media screen and (min-width: $breakpoint-m) {
    margin-top: -$space-25;
  }
}

.columns {
  display: flex;
  flex-direction: column;
  margin-top: $space-150;
  width: 100%;

  @media screen and (min-width: $breakpoint-m) {
    flex-direction: row;
  }
}

.column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:last-child {
    margin-top: $space-300;

    @media screen and (min-width: $breakpoint-m) {
      align-items: flex-end;
      margin-top: 0;
    }
  }
}
