@import 'node_modules/@ingka/variables/style';
@import 'style/mixins';

.container {
  background-color: $colour-neutral-2;
  margin-top: $space-300;
  padding-top: $space-150;
  width: 100%;
}

.inner {
  @include container;
}
