@import 'node_modules/@ingka/variables/style';

.form {
  margin-top: $space-200;
}

.choice {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  margin-top: $space-75;
  row-gap: 1rem;

  > * {
    margin: 0;
  }
}

.error {
  margin-bottom: $space-150;
  width: 100%;
}
