@import 'node_modules/@ingka/variables/style';

.actions {
  display: flex;

  > * + * {
    margin-left: $space-150;
  }
}

.error {
  margin-bottom: $space-150;
  width: 100%;
}

.form {
  display: grid;
  gap: $space-300;
}

.formSection {
  display: grid;
  gap: $space-150;
}

.fieldset {
  display: grid;
  gap: $space-200;
}

.field {
  margin-bottom: 0;
  max-width: 46ch;
}

.subHeader {
  margin-bottom: $space-75;
}

.exampleDescription {
  color: $colour-neutral-5;
  margin-top: $space-25;
}

.businessStructure {
  display: flex;

  > * + * {
    margin-left: $space-150;
  }
}

.statusHeading {
  margin-bottom: $space-50;
}

.grade {
  margin-bottom: 0;

  .gradeHeading {
    margin-bottom: $space-50;
  }
}

.gradeInput {
  margin-top: $space-75;
}

.additionalInformation {
  margin-top: $space-100;
}

.columns {
  display: grid;
  gap: $space-200;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
