@import 'node_modules/@ingka/variables/style';

.hoverModal {
  margin-bottom: 10px;
  position: absolute;
  z-index: 1;

  .box {
    background-color: $colour-neutral-1;
    border-radius: $radius-s;
    box-shadow: 0 4px 16px 0 #00000030;
    display: flex;
    height: auto;
    position: relative;
  }

  .box::after {
    border-bottom: none;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $colour-neutral-1;
    bottom: -15px;
    content: ' ';
    position: absolute;
  }
}
