@import 'node_modules/@ingka/variables/style';

.header {
  flex-direction: column;

  @include lg {
    flex-direction: row;
  }
}

.field {
  margin-bottom: 0;
  max-width: 46ch;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.start {
  flex-shrink: 1;
  overflow: hidden;
}

.heading {
  margin-bottom: $space-125;
}
