.colId {
  width: 14%;
}

.colProductName {
  width: 12;
}

.colPa {
  width: 12%;
}

.colSourcingTimestamp {
  width: 20%;
}

.colGrade {
  width: 12%;
}

.colStatus {
  width: 12%;
}

.colDate {
  width: 12%;
}
