@import 'node_modules/@ingka/variables/style';

.header {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.description {
  margin-top: $space-50;
}
