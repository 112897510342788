@import 'node_modules/@ingka/variables/style';

.th {
  padding-bottom: 20px;
  padding-top: 20px;
  white-space: nowrap;
}

.button {
  color: $colour-neutral-6;
  font-weight: normal;
  text-decoration: none;

  &:disabled {
    color: $colour-neutral-6;
  }

  svg {
    color: $colour-text-and-icon-4;
  }
}

.active {
  color: $colour-text-and-icon-2;
  text-decoration: none;

  svg {
    color: $colour-text-and-icon-1;
  }
}
