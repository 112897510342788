@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.container {
  position: relative;
  display: inline;
}

.copyBtn {
  &:hover {
    cursor: pointer;
  }
}

.confirm {
  visibility: visible;
  opacity: 1;
  position: absolute;
  background: $colour-interactive-primary-bg-hover;
  color: white !important;
  padding: $space-25;
  font-size: 0.75rem !important;
  font-weight: 700 !important;
}

.confirmInline {
  position: relative;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s $duration-slow-web, opacity $duration-slow-web $ease-exit;
}
