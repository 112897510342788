@import 'node_modules/@ingka/variables/style';
/* stylelint-disable-next-line scss/at-import-no-partial-leading-underscore */
@import 'node_modules/@ingka/grid/_mixins';

.container {
  display: flex;
  height: 100vh;
}

.side_column {
  align-items: center;
  background: $colour-neutral-7;
  display: flex;
  padding: 0 $space-400;
  width: 45%;
}

.logo_container {
  padding-top: $space-200;
  position: absolute;
  top: 0;

  .logo {
    display: block;
    height: 32px;
  }
}

.text_container {
  width: 330px;

  .headline {
    color: $colour-static-ikea-brand-yellow;
  }

  .text {
    color: $colour-neutral-1;
  }
}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: $space-750;
}

.footer {
  background-color: transparent;
  bottom: 0;
  position: absolute;
}
