@import 'node_modules/@ingka/variables/style';

.row {
  > td {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  &.highlighted {
    > td {
      background-color: $colour-semantic-positive;
    }
  }

  &:not(.highlighted):nth-child(even) {
    > td:not(.selectCell) {
      background-color: $colour-neutral-2;
    }
  }
}

table .selectCell {
  border-color: transparent;
  padding-right: $space-50;
}

.displayHoverModal {
  display: none;
  position: absolute;
  left: 100px;
  top: -240px;
}

.inventoryHoverModal {
  padding: $space-100;
}

.idRow {
  width: max-content;
  position: relative;
}

.idLink {
  text-transform: uppercase;
}

.idLink:hover + .displayHoverModal {
  display: block;
}

.hoverModalImage {
  height: auto;
  margin: auto;
  width: 175px;
}

.details {
  column-gap: $space-125;
  display: grid;
  grid-template-columns: 9em 100%;
  margin-left: $space-100;
  white-space: nowrap;
  width: min-content;
}

.status {
  text-transform: capitalize;
}
