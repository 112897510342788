@import 'node_modules/@ingka/variables/style';

.table {
  overflow: visible !important;
  width: 100%;

  > table {
    width: 100%;
  }
}

.tableHeader {
  > th {
    padding-bottom: 20px;
    padding-top: 20px;
    white-space: nowrap;

    &:empty {
      border-color: transparent;
    }

    .selectAll {
      margin-top: 10px;
    }
  }

  .selectAllHeader {
    width: 2%;
  }
}
