@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.businessStructure {
  display: flex;
  margin-top: $space-75;

  > * + * {
    margin-left: $space-150;
  }
}

.artNoHeading {
  margin-top: $space-100;
}

.artNo {
  background: $colour-text-bg-dark;
  color: white;
  margin-top: $space-75;
  padding: 6px 12px;
  width: max-content;
}
