@import 'node_modules/@ingka/variables/style';

.itemHistory {
  margin-top: $space-250;

  .historyHeader {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    .viewAll {
      cursor: pointer;
      font-weight: bold;
      opacity: 0.9;
      text-decoration: underline;
    }

    .viewAll:hover {
      opacity: 1;
    }
  }

  .table:not(table) {
    overflow: visible !important;
  }

  table {
    width: 100% !important;

    .dateColumn {
      max-width: 6rem;
    }
  }

  .historyEmpty {
    font-style: italic;
    margin-top: $space-125;
  }
}

.activityType {
  cursor: pointer;
  text-decoration: underline;
}

.displayHoverModal {
  display: none;
  margin-left: -150px;
  position: absolute;
  z-index: 1000;
}

.hoverModal {
  bottom: 20px;
  margin-bottom: 10px;
  position: absolute;
  z-index: 1;

  .box {
    background-color: $colour-neutral-1;
    border-radius: $radius-s;
    box-shadow: 0 4px 16px 0 #00000030;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: $space-150;
    position: relative;
  }

  .box::after {
    border-bottom: none;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid $colour-neutral-1;
    bottom: -15px;
    content: ' ';
    left: 170px;
    position: absolute;
  }
}

.activityType:hover + .displayHoverModal {
  display: block;
}

.username {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.username:hover {
  height: auto;
  overflow: visible;
  white-space: normal;
}

.btnGroup {
  margin-top: -4px;
}
