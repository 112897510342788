@use 'sass:color';
@import 'node_modules/@ingka/variables/style';

.container {
  align-items: flex-end;
  bottom: $space-150;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  position: fixed;
  right: $space-150;
  z-index: 100;
}

.menuItem {
  span:global(.btn__inner) {
    justify-content: flex-start;
  }
}

.button {
  box-shadow: 2px 4px 20px rgba(0, 0, 0, 25%);

  span:global(.btn__inner) {
    background-color: $colour-interactive-emphasised-bg-default;

    :hover > & {
      background-color: $colour-interactive-emphasised-bg-hover;
    }

    :active > & {
      background-color: $colour-interactive-emphasised-bg-pressed;
    }
  }
}

.menu {
  background-color: white;
  border-radius: $space-50;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 10%);
  display: grid;
  gap: $space-50;
  margin-bottom: $space-50;
  padding: $space-50;

  .menuItem {
    span:global(.btn__inner) {
      border-radius: $radius-s;
    }
  }
}
