@import 'node_modules/@ingka/variables/style';

.itemTableRow {
  .selectCell {
    padding-right: $space-50;
  }

  .rowDeleteBtn {
    border-color: transparent;

    .deleteBtn {
      span {
        min-height: 0 !important;
      }
    }
  }

  td {
    padding-bottom: $space-100 !important;
    padding-top: $space-100 !important;
  }

  .borderLess {
    border-bottom: transparent !important;
  }
}
