@import 'node_modules/@ingka/variables/style';

.form {
  justify-content: center;
  width: 335px;

  > * + * {
    margin-top: $space-150;
  }

  .decommission {
    color: red;
  }
}



.field {
  margin: $space-150 0 0 0;
}

.message {
  margin-top: $space-300;
}
