@import 'node_modules/@ingka/variables/style';

.details {
  column-gap: $space-125;
  display: grid;
  grid-template-columns: 33% 1fr;
  margin-top: $space-200;
  row-gap: $space-125;
  width: 100%;
}
