@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.start {
  flex-shrink: 1;
  overflow: hidden;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.detailsWrapper {
  width: 100%;

  .detailsId {
    margin-bottom: $space-150;
  }
}

.referenceItemsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > div:first-child {
    width: 53%;
  }

  > div:nth-child(2) {
    width: 43%;
  }
}
