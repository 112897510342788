#root {
  min-height: 100vh;

  > * {
    min-height: 100vh;
  }
}

option:first-child {
  display: none;
}
