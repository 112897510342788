@import 'node_modules/@ingka/variables/style';

.filterSatusPill {
  position: relative;
}

.dropDown {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: $radius-l;
  box-shadow: 5px 5px 20px 3px #dfdfdf;
  box-shadow: 1px 3px 8px 0 #929292;
  min-width: 180px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
  position: absolute;
  top: 65px;
  z-index: 100;
}

.radioButton {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0.75rem 0;
  width: 100%;

  input[type='radio'] {
    left: auto;
    top: 0.375rem;
  }
}
