/* stylelint-disable selector-class-pattern */
@use 'sass:color';
@import 'node_modules/@ingka/variables/style';

.statusInput {
  display: flex;

  &Item {
    flex-direction: unset !important;
    justify-content: center;
    margin-right: $space-100;
    width: 90px !important;
  }
}
