@import 'node_modules/@ingka/variables/style';

.grid {
  column-gap: $space-150;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}

.single {
  column-gap: $space-150;
  display: grid;

  // grid-template-columns: 50% 50%;
  width: 100%;
}
