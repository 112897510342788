@import 'node_modules/@ingka/variables/style';

.activityTypeHeading {
  font-weight: bold;
  margin-bottom: $space-50;
}

.details {
  column-gap: $space-125;
  display: grid;
  grid-template-columns: 80px auto;
  margin-right: $space-100;
  white-space: nowrap;
  width: min-content;
}

.username {
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.username:hover {
  height: auto;
  overflow: visible;
  white-space: normal;
}
