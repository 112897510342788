@import 'node_modules/@ingka/variables/style';

.field {
  max-width: 46ch;

  .fieldLabel {
    color: $colour-neutral-6;
    font-weight: 100;
  }
}

.datePicker {
  height: 3rem;
  width: 100%;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  border: 1px solid $colour-neutral-5;
  border-radius: $radius-s;
}

// @include heading-s;
