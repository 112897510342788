@import 'node_modules/@ingka/variables/style';

.statusText {
  margin-bottom: $space-50;
}

.pending {
  color: $colour-semantic-caution !important;
}

.available {
  color: $colour-semantic-positive !important;
}

.departed {
  color: $colour-semantic-negative !important;
}

.reserved {
  color: $colour-semantic-negative !important;
}
