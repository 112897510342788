@import 'node_modules/@ingka/variables/style';

.field {
  margin-bottom: 0;
  max-width: 46ch;
}

.date {
  column-gap: $space-150;
  display: grid;
}

.dateLegend {
  grid-column: 1 / 3;
  grid-row: 1 / 2;
}

.date > *:not(:first-child) {
  grid-row: 2 / 3;
}

.dateYear {
  grid-column: 1 / 2;
}

.dateWeek {
  grid-column: 2 / 3;
}
