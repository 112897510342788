@import 'node_modules/@ingka/variables/style';

.table {
  width: 100%;

  > table {
    width: 100%;
  }
}

.tableHeader {
  > th {
    padding-bottom: 20px;
    padding-top: 20px;
    white-space: nowrap;

    &:empty {
      border-color: transparent;
    }
  }

  .selectAllHeader {
    width: 2%;
  }
}

.colId {
  width: 15%;
}

.colSource {
  width: 75%;
}

.colDeleteSource {
  width: 10%;
}

.colAmount {
  width: 20%;
}

.colDate {
  width: 20%;
}
