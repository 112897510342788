@import 'node_modules/@ingka/variables/style';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.imageContainer {
  height: 204px;
  width: 420px;
}

.skeleton {
  height: 225px;
  width: 450px;
}

.error {
  align-items: center;
  background-color: $colour-neutral-2;
  display: flex;
  height: 225px;
  justify-content: center;
  width: 450px;
}

.image {
  height: 204px;
  opacity: 0;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 420px;
}

.imageLoaded {
  opacity: 1;
}

.buttons {
  margin-top: $space-300;
}

.button + .button {
  margin-left: $space-150;
}

.message {
  margin-top: $space-150;
}
