@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.header {
  flex-direction: column;

  @include lg {
    flex-direction: row;
  }
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.start {
  flex-shrink: 1;
  overflow: hidden;
}

.artNo {
  margin-top: $space-75;

  > span {
    background: $colour-text-bg-dark;
    color: white !important;
    display: inline-block;
    padding: 6px 12px;
    width: max-content;
  }
}

.copyBtn {
  margin: 2px 0 0 $space-25;
}

.value {
  height: 22px;
}

.subHeading {
  margin-top: $space-200;
}

.table {
  margin-top: $space-125;

  table {
    width: 100%;
  }
}

.label {
  display: none;
}

.productName,
.productDescription {
  max-width: 60ch;
}
