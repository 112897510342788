@import 'node_modules/@ingka/variables/style';

.header {
  margin-bottom: $space-200;
}

.headerStart {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.skeleton {
  height: 243px;
}

.error {
  width: 100%;
}

.total {
  margin-top: $space-50;
}

.colId {
  width: 14%;
}

.colProductName {
  width: 12;
}

.colPa {
  width: 12%;
}

.colDescription {
  width: 20%;
}

.colGrade {
  width: 12%;
}

.colStatus {
  width: 12%;
}

// .colSgf {
//   width: 8%;
// }

// .colSgf {
//   width: 8%;
// }

.colDate {
  width: 12%;
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: $space-200;
  width: 100%;
}

.searchBar {
  display: flex;
  justify-content: flex-start;
}

.searchBar div {
  padding-right: 10px;
}

.selectedFiltes {
  margin-top: $space-100;
}
