@import 'node_modules/@ingka/variables/style';
@import 'style/mixins';

.container {
  @include container;

  display: grid;
  gap: $space-50;
  grid-template-areas:
    'branding'
    'user'
    'navigation';

  @media screen and (min-width: $breakpoint-m) {
    grid-template-areas:
      'branding user'
      'navigation navigation';
  }

  @media screen and (min-width: $breakpoint-l) {
    grid-template-areas: 'branding navigation user';
    grid-template-columns: 25% 2fr 1fr;
  }
}

.logo {
  display: block;
  height: 30px;
}

.title {
  display: block;
  margin: 0 0 0 $space-150;
  text-transform: uppercase;
}

.branding {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-area: branding;
  justify-content: flex-start;
  padding-bottom: $space-100;
  padding-top: $space-100;
}

.user {
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-area: user;

  @media screen and (min-width: $breakpoint-m) {
    justify-content: flex-end;
  }
}

.link {
  color: $colour-neutral-7;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.navigation {
  align-items: center;
  display: flex;
  grid-area: navigation;
  justify-content: flex-start;
}
