@import 'node_modules/@ingka/variables/style';

.start {
  flex-shrink: 1;
  overflow: hidden;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tabs {
  width: 100%;
}

.detailsWrapper {
  margin-bottom: $space-150;
}

.colId {
  width: 14%;
}

.colProductName {
  width: 12;
}

.colPa {
  width: 12%;
}

.colDescription {
  width: 20%;
}

.colGrade {
  width: 12%;
}

.colStatus {
  width: 12%;
}

.colDate {
  width: 12%;
}
