@import 'style/mixins';

.container {
  @include container;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
