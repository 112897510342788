@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.details {
  column-gap: $space-125;
  display: grid;
  grid-template-columns: 33% 1fr;
  margin-top: $space-200;
  row-gap: $space-125;
  width: 100%;
}

.comment {
  display: flex;
  flex-direction: column;

  .commentBy {
    color: $colour-neutral-5;
    display: flex;
    flex-direction: column;
    margin-top: $space-75;
  }
}
