@import 'node_modules/@ingka/variables/style';

.itemTableRow {
  .selectCell {
    padding-right: $space-50;
  }

  td {
    padding-bottom: $space-100 !important;
    padding-top: $space-100 !important;
  }

  .borderLess {
    border-bottom: transparent !important;
  }
}
