@import 'node_modules/@ingka/variables/style';

.contentWrapper {
  > h1 {
    margin-bottom: $space-50;
  }

  > button {
    margin-top: $space-150;
  }
}
