@import 'node_modules/@ingka/variables/style';

.heading {
  margin-bottom: $space-200;
}

.activityheader {
  margin-bottom: $space-50;
  position: relative;
  .remove {
    position: absolute;
    right: 0;
  }
}