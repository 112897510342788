@import 'node_modules/@ingka/variables/style';
@import 'style/variables';

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.start {
  flex-shrink: 1;
  overflow: hidden;
}

.tabs {
  width: 100%;
}

.detailsWrapper {
  width: 100%;

  .detailsId {
    margin-bottom: $space-150;
  }

  .field {
    max-width: 16rem;
  }
}

.actions {
  display: flex;
  margin-top: $space-250;

  button:first-child {
    width: 20rem;
  }

  button:last-child {
    width: 10rem;
  }

  > * + * {
    margin-left: $space-150;
  }
}
