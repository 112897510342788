@import 'node_modules/@ingka/variables/style';

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: $space-300;
  width: 100%;
}

.pages {
  align-items: center;
  display: flex;
  margin: 0 $space-150;
}

.page {
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  border-radius: 100%;
  box-shadow: none;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 $space-50;
  padding: 0;
  text-align: center;
  width: 40px;

  &:disabled {
    cursor: default;
  }
}

.active {
  font-weight: bold;
  text-decoration: none;
}
