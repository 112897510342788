@import 'node_modules/@ingka/variables/style';

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.start {
  justify-content: flex-start;

  > * + * {
    margin-left: $space-100;
  }
}

.end {
  justify-content: flex-end;

  > * + * {
    margin-left: $space-75;
  }
}
