@import 'node_modules/@ingka/variables/style';

.field {
  margin-bottom: 0;
  max-width: 46ch;
}

.hfbpa {
  column-gap: $space-150;
  display: grid;
}

.hfb {
  grid-column: 1 / 2;
}

.pa {
  grid-column: 2 / 3;
}
