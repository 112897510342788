@use 'sass:color';
@import 'node_modules/@ingka/variables/style';

.container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  max-height: 40px;
  position: relative;
}

.menuItem {
  span:global(.btn__inner) {
    justify-content: flex-start;
  }
}

.button {
  span:global(.btn__inner) {
    background-color: black;
    max-height: 40px;
    max-width: 40px;

    :hover > & {
      background-color: color.adjust(black, $lightness: 5%);
    }

    :active > & {
      background-color: color.adjust(black, $lightness: -5%);
    }
  }
}

.menu {
  background-color: white;
  border-radius: $radius-s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 10%);
  display: flex;
  flex-direction: column;
  grid-gap: $space-50;
  margin-top: $space-300;
  padding: $space-50;
  position: absolute;
  right: 0;
  top: 0;
  width: max-content;

  .menuItem {
    span:global(.btn__inner) {
      border-radius: $radius-s;
    }
  }
}
