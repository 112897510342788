@import 'node_modules/@ingka/variables/style';
@import 'node_modules/@ingka/hyperlink/style';

.container {
  align-items: center;
  display: flex;
  flex-direction: row;

  > * + * {
    margin-left: $space-150;
  }
}

.icon + .message {
  margin-left: $space-100;
}

.action {
  font-weight: 600 !important;
}
