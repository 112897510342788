/* stylelint-disable no-descending-specificity */
@use 'sass:color';
@import 'node_modules/@ingka/variables/style';

.headerWrapper {
  display: flex;
  flex-direction: column;
  margin-top: $space-250;

  .headerTitle {
    display: flex;
    justify-content: space-between;
  }

  .selectAll {
    display: flex;
    padding-top: $space-125;

    .selectAllCheckbox {
      margin-right: $space-125;
    }
  }
}

.fromReferenceList {
  background: $colour-neutral-2;
  border-radius: $radius-m;
  padding: 0 $space-125 $space-150 $space-125;

  th,
  td {
    background: $colour-neutral-2;
    border-bottom: 1px solid $colour-neutral-3;

    // width: 100%;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
}

.fromReferenceList,
.atReferenceList {
  .table {
    margin-top: $space-125;

    .productName {
      width: 10rem;
    }
  }
}

.tableHeader {
  width: 100%;

  > th {
    padding-bottom: 20px;
    padding-top: 20px;
    white-space: nowrap;

    &:empty {
      width: 1%;
    }
  }

  .checkbox {
    width: 5%;
  }

  // .colShipmentId {
  //   width: 15%;
  // }

  .colProductId {
    width: 12rem;
  }

  .colProductName {
    width: 15rem;
  }

  .colStatus {
    width: 5rem;
  }
}

.borderLess {
  border-bottom: transparent !important;
}
