/* stylelint-disable no-descending-specificity */
@use 'sass:color';
@import 'node_modules/@ingka/variables/style';

.header {
  display: flex;
  justify-content: space-between;
  margin-top: $space-250;
  width: 600px;
}

.table {
  margin-top: $space-125;

  td {
    vertical-align: middle;
  }

  table {
    min-width: 600px;
  }
}

.deleteItem {
  padding: $space-50;
}

.tableHeader {
  > th {
    padding-bottom: 20px;
    padding-top: 20px;
    white-space: nowrap;

    &:empty {
      // border-color: transparent !important;
      width: 1%;
    }
  }

  .checkbox {
    width: 1%;
  }

  .productName {
    width: 10rem;
  }
}

.tableHead {
  tr {
    th {
      height: 4.5rem;
      vertical-align: middle;
    }
  }
}

.borderLess {
  border-bottom: transparent !important;
}
