@import 'node_modules/@ingka/variables/style';

.row {
  > td {
    padding-bottom: 20px;
    padding-top: 20px;
  }

  &:nth-child(even) {
    > td {
      background-color: $colour-neutral-2;
    }
  }
}
