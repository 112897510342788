@import 'node_modules/@ingka/variables/style';

.container {
  background: #f5f5f5;
  border-radius: $radius-s;
  padding: $space-75;
}

.values {
  align-items: center;
  display: flex;
}

.dimmed {
  color: $colour-neutral-5;
  padding-left: $space-25;
}
