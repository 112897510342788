@import 'node_modules/@ingka/variables/style';

.sgf {
  display: flex;
  flex-direction: column;
}

.selectContainer {
  display: flex;
  justify-content: space-between;
}

.field {
  max-width: 46ch;
}

.select {
  max-width: 12ch;
}
