@import 'node_modules/@ingka/variables/style';

.choice {
  display: flex;
  flex-wrap: wrap;

  // margin-top: $space-75;

  > * {
    margin: 0 $space-100 $space-100 0;
    min-width: 6.5rem;
  }
}
