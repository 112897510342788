@import 'node_modules/@ingka/variables/style';

.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.divider {
  border-bottom: thin solid $colour-neutral-3;
  margin-bottom: $space-300;
  padding-bottom: $space-300;
}
